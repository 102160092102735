import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import parse from './parse';

import './autocomplete.css'


export default function SearchAutocomplete({setAddress}) {

  var baseAddress = null

  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    // dev code
    baseAddress = 'http://dev-customer-back.voterstack.com:5015/'
  } else {
    // production code
    baseAddress = 'https://cbe.voterstack.com:5015/'
  }


  const [value, setValue] = React.useState({description: '', structured_formatting: {}});
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState([]);
  const loaded = React.useRef(false);

  const [addressLoad, setAddressLoad] = React.useState(false)

  React.useEffect(() => {


    if(value === null){
        setValue({description: '', structured_formatting: {}})
        return
    }
    

    if(addressLoad){
        return
    }

    if(value.description === inputValue){
        console.log(value.structured_formatting)
        
        // For future validation
        setAddress({
            addressLineOne: value.structured_formatting.addressLineOne,
            city: value.structured_formatting.city,
            state: value.structured_formatting.state,
            zipCode: value.structured_formatting.zipCode
        })
    }

    setAddressLoad(true)

    fetch(baseAddress + "pollAddress", {
        method: "POST",
        headers: {
        "content-type" : 'application/json'
        },
        body: JSON.stringify({
            addressString: inputValue
        })
    })
    .then(res => {
        if (res.ok) return res.json()
    })
    .then(res => {

        if(res === undefined){
        } else {
            setOptions(res)
        }

        setAddressLoad(false)
    })


  }, [value, inputValue]);

  return (
    <Autocomplete
      sx={{ width: 300 }}
      getOptionLabel={(option) =>
        typeof option === 'string' ? option : option.description
      }
      filterOptions={(x) => x}
      options={options}
 
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      style={{width: '100%', marginTop: '45px'}}
      noOptionsText="No locations"
      onChange={(event, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField {...params} label="Search Address" fullWidth />
      )}
      renderOption={(props, option) => {
        const { key, ...optionProps } = props;
        const matches =
          option.structured_formatting.main_text_matched_substrings || [];

        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match) => [match.offset, match.offset + match.length]),
        );
        return (
          <li key={key} {...optionProps}>
            <Grid container sx={{ alignItems: 'center'}}>
              <Grid item sx={{ display: 'flex', width: 44 }}>
                <LocationOnIcon sx={{ color: 'text.secondary' }} />
              </Grid>
              <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                {parts.map((part, index) => (
                  <Box
                    key={index}
                    component="span"
                    sx={{ fontWeight: part.highlight ? 'bold' : 'regular' }}
                  >
                    {part.text}
                  </Box>
                ))}
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
}

