import './App.css';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import PrivateRoutes from './utilities/privateRoutes.js';
import Login from './login/login'
import SignUp from './signUp/signUp'
import HowItWorks from './howItWorks/howItWorks'
import SuccessfulTransaction from './signUp/successfulTransaction/successfulTransaction'
import FailedTransaction from './signUp/failedTransaction/failedTransaction'
import OfficialResponse from './officialResponse/officialResponse'
import PolicySelect from './policySelect/policySelect'
import PolicyDebate from './policyDebate/policyDebate'
import ActivityHistory from './activityHistory/activityHistory'
import MyProfile from './myProfile/myProfile'
import FeedbackSupport from './feedbackSupport/feedbackSupport.js';
import UserSettings from './userSettings/userSettings.js'
import Policies from './policies/policies.js'
import Support from './support/support.js'
import PasswordReset from './passwordReset/passwordReset.js'

function App() {
  return (
    <div>
      <Router>
          <Routes>
            <Route element={<PrivateRoutes />}>
              <Route element={<OfficialResponse />} path="/officialResponses" exact/>
              <Route element={<PolicySelect />} path="/policySelect" exact/>
              <Route element={<PolicyDebate />} path="/policyDebate" exact/>
              <Route element={<ActivityHistory />} path="/activityHistory" exact/>
              <Route element={<MyProfile />} path="/myProfile" exact/>
              <Route element={<FeedbackSupport />} path="/feedbackSupport" exact/>
              <Route element={<UserSettings />} path="/userSettings" exact/>
            </Route>
            <Route path="*" element={<Navigate to='/'/>}></Route>
            <Route path="/" element={<Login />}></Route> 
            <Route path="/signup" element={<SignUp />}></Route>
            <Route path="/signup_successful" element={<SuccessfulTransaction />}></Route>
            <Route path="/signup_failed" element={<FailedTransaction />}></Route>
            <Route path="/howItWorks" element={<HowItWorks />}></Route>
            <Route path="/policies" element={<Policies />}></Route>
            <Route path="/support" element={<Support />}></Route>
            <Route path="/passwordReset" element={<PasswordReset />}></Route>
          </Routes>
      </Router>
    </div>
  );
}

export default App;
