import React, {useState, useRef} from 'react'
import './firstTime.css'
import voterStackLogo from '../images/SVG_Logo.svg'
import API from "../utilities/api"

export default function FirstTime({firstTimeState, closeFirstTime}) {

    async function closeFirstTimeHandler(){

        await API.post('removeFirstTime', {})
        .then(res => {
            console.log(res.data)
            closeFirstTime()
        })
        .catch(err => { 
            console.log(err)
        })

    }

  return (
    <div style={{display: firstTimeState == true || firstTimeState === 'true' ? 'block' : 'none'}} className='common_overlay'>
        <div className='first_time_section first_time_screen_background'>
            <img className='first_time_icon_center' style={{marginTop: '3rem'}} src={voterStackLogo}></img>
            <div className='first_time_title' style={{marginTop: '1rem'}}>Let Your Voice Be Heard!</div>
            <div className='first_time_description'>
                As a constituent for your districts here in the United States of America, 
                it is your civil right as an American for your voice to be heard through the political authority of voting. 
                Ideas are brought to a vote, votes turn in to policy, policy turns into change. 
                You will have to work with your fellow constituents for each of your districts to determine what changes are important and your position on the subject. You will do this in <span style={{textDecoration: 'underline'}}>two</span> alternating sessions which continuosly cycle. These are those sessions:</div>
            <div className='first_time_subtitle'>Policy Selection Session</div>
            <div className='first_time_text'><span style={{fontWeight: 'bold'}}>This session will last for 24 hours and starts at 9:00AM CST.</span> During this window, you will be given the option to create a policy which can be viewed by other constituents in your district. In addition, you will be given the option to vote for a fellow constituents policy. These votes will accumulate and determine a winning priority policy by the end of the session.</div>
            <div className='first_time_subtitle'>Policy Debate Session</div>
            <div className='first_time_text'><span style={{fontWeight: 'bold'}}>This session will last for 24 hours and starts at 9:00AM CST.</span> During this window, you will see the constituent elected priority policy. You will get to choose which position you support for said policy as well as the ability to provide a compelling argument as to why it is good or bad. Once completed, you will have the option to support arguments from which side you've chosen. These votes of position, arguments, and supporting votes will provide insight as to what your district supports or opposes. This information will be sent to your respective elected officials at the end of this session. The cycle will start back over to the Policy Selection session.</div>

            <div className='first_time_text'>By using our website, you agree to all the terms and conditions outlined
                in the <a style={{textDecoration: 'underline'}} href='https://voterstack.com/policies' target='_blank'>Terms Of Service</a>. If you do not agree with any part of this
                policy, please refrain from using our website.</div>

            <div className='common_button common_ack_button' onClick={()=>closeFirstTimeHandler()}>Let's Go!</div>
        </div>
    </div>
  )
}
