import React, {useEffect, useRef, useState} from 'react'
import './previewCoverage.css'
import success from '../../images/success.png'
import RepresentativesBox from '../../representativesBox/representativesBox'
import ProgressBar from '../../progressBar/progressBar'

export default function PreviewCoverage({display, representatives, slide}) {

    useEffect(() => {
    window.scrollTo(0, 0)
    }, [])

    // Error Related
    const [errorDisplay, setErrorDisplay] = useState('none')
    const [errorDetails, setErrorDetails] = useState({title: '', description: ''})

    function closeError(){
        setErrorDisplay('none')
    }


    //////////////////////////////////////////////////////////////
    // slideHandler
    //////////////////////////////////////////////////////////////
    function slideHandler(direction){
        slide('previewCoverage', direction)
    }

    //////////////////////////////////////////////////////////////
    // slideHandler
    //////////////////////////////////////////////////////////////
    function slideHandler(direction){
        slide('previewCoverage', direction)
    }

  return (
    <div style={{display: display ? 'block' : 'none'}}>
        
        <img className='common_icon_center' src={success}></img>
        <div className='common_title_subtext'>Great news! You have coverage available!</div>
        <ProgressBar page='reviewOfficials'></ProgressBar>
        <div className='common_note'>You have officials in your area who are actively receiving constituent proposals. </div>
        
        <div style={{display: representatives.townStatus ? 'block' : 'none'}}>
            <div className='common_section_title_2'>Town Officials</div>
            <div className='common_divider'></div>
            { representatives.town.map((representative) => 
                <div key={representative.name}>
                    <RepresentativesBox representative={representative}></RepresentativesBox>
                </div>
            )}

            
        </div>

        <div style={{display: representatives.cityStatus ? 'block' : 'none'}}>
            <div className='common_section_title_2'>City Officials</div>
            <div className='common_divider'></div>
            { representatives.city.map((representative) => 
                <div key={representative.name}>
                    <RepresentativesBox representative={representative}></RepresentativesBox>
                </div>
            )}
        </div>

        <div style={{display: representatives.townshipStatus ? 'block' : 'none'}}>
            <div className='common_section_title_2'>Township Officials</div>
            <div className='common_divider'></div>
            { representatives.township.map((representative) => 
                <div key={representative.name}>
                    <RepresentativesBox representative={representative}></RepresentativesBox>
                </div>
            )}
        </div>

        <div style={{display: representatives.schoolStatus ? 'block' : 'none'}}>
            <div className='common_section_title_2'>School Officials</div>
            <div className='common_divider'></div>
            { representatives.school.map((representative) => 
                <div key={representative.name}>
                    <RepresentativesBox representative={representative}></RepresentativesBox>
                </div>
            )}
        </div>

        <div style={{display: representatives.countyStatus ? 'block' : 'none'}}>
            <div className='common_section_title_2'>County Officials</div>
            <div className='common_divider'></div>
            { representatives.county.map((representative) => 
                <div key={representative.name}>
                    <RepresentativesBox representative={representative}></RepresentativesBox>
                </div>
            )}
        </div>

        <div style={{display: representatives.stateStatus ? 'block' : 'none'}}>
            <div className='common_section_title_2'>State Officials</div>
            <div className='common_divider'></div>
            { representatives.state.map((representative) => 
                <div key={representative.name}>
                    <RepresentativesBox representative={representative}></RepresentativesBox>
                </div>
            )}
        </div>

        <div style={{display: representatives.federalStatus ? 'block' : 'none'}}>
            <div className='common_section_title_2'>Federal Officials</div>
            <div className='common_divider'></div>
            { representatives.federal.map((representative) => 
                <div key={representative.name}>
                    <RepresentativesBox representative={representative}></RepresentativesBox>
                </div>
            )}
        </div>
        
        <div className='common_button_combination' style={{marginTop: '3rem'}}>
            <div className='common_button common_cancel_button' onClick={()=>slideHandler(false)} >Cancel</div>
            <div></div>
            <div className='common_button common_submit_button' onClick={()=>slideHandler(true)}>Looks good!</div>
        </div>
    </div>
  )
}
