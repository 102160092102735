import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
  CardElement,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement
} from "@stripe/react-stripe-js";
import './expressCheckout.css'


if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  // dev code
  var websiteURL = "http://dev-customer-front.voterstack.com:3000"
} else {
  // production code
  var websiteURL = "https://voterstack.com"
}

export default function CheckoutPage({checkoutData, clientSecret}) {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    let status = await stripe.confirmSetup({
      elements,
      confirmParams: {
        // Return URL where the customer should be redirected after the SetupIntent is confirmed.
        return_url: websiteURL + "/signup_successful?trackerID=" + checkoutData.trackerID,
      },
    })
    .then(function(result) {     
      
      if (result.error) {
        // Inform the customer that there was an error.
      }

      return result
    });

    console.log(status)

    console.log(status.error)

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    
    if (status.error.type === "card_error" || status.error.type === "validation_error") {
      setMessage(status.error.message);
    } else {
      setMessage("An unexpected error occurred.");
    } 

    setIsLoading(false);
  };

  const paymentElementOptions = {
    layout: "tabs"
  }

  return (
    <form id="payment-form" onSubmit={handleSubmit}>

      <PaymentElement id="payment-element" options={paymentElementOptions} />
      <button className='checkout_button' disabled={isLoading || !stripe || !elements} id="submit">
        <span id="button-text">
          {isLoading ? <div className="spinner" id="spinner"></div> : "Pay now"}
        </span>
      </button>
      {/* Show any error or success messages */}
      {message && <div id="payment-message">{message}</div>}
    </form>
  );
}