import React, {useEffect, useState, useRef} from 'react'
import Select from 'react-select'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ErrorWindow from '../../errorWindow/errorWindow'
import userPreview from '../../images/newPerson.png'
import '../../common.css'
import ProgressBar from '../../progressBar/progressBar'


export default function BasicInformation({display, setGeneralInfo, slide}) {
    var baseAddress = null

    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      // dev code
      baseAddress = 'http://dev-customer-back.voterstack.com:5015/'
    } else {
      // production code
      baseAddress = 'https://cbe.voterstack.com:5015/'
    }


    useEffect(() => {
        window.scrollTo(0, 0)
        }, [])

    // Error Related
    const [errorDisplay, setErrorDisplay] = useState('none')
    const [errorDetails, setErrorDetails] = useState({title: '', description: ''})

    function closeError(){
        setErrorDisplay('none')
    }

    let firstName = useRef()
    let lastName = useRef()
    let phoneNumber = useRef()
    let emailAddress = useRef()
    let password = useRef()
    let confirmPassword = useRef()
    const [birthDate, setBirthDate] = useState(new Date());

    const [selectedAgreement, setSelectedAgreement] = useState(false);

    // Selected Gender
    const [selectedGender, setSelectedGender] = useState('');
    const [isGenderClearable, setIsGenderClearable] = useState(false);
    const [isGenderSearchable, setIsGenderSearchable] = useState(true);
    const [isGenderDisabled, setIsGenderDisabled] = useState(false);
    const [isGenderLoading, setIsGenderLoading] = useState(false);
    const [isGenderRtl, setIsGenderRtl] = useState(false);

    const gender = [
        { value: 'Male', label: 'Male' },
        { value: 'Female', label: 'Female' },
        { value: 'Other', label: 'Other' }
    ] 


    // Selected Ethnicity
    const [selectedEthnicity, setSelectedEthnicity] = useState('');
    const [isEthnicityClearable, setIsEthnicityClearable] = useState(false);
    const [isEthnicitySearchable, setIsEthnicitySearchable] = useState(true);
    const [isEthnicityDisabled, setIsEthnicityDisabled] = useState(false);
    const [isEthnicityLoading, setIsEthnicityLoading] = useState(false);
    const [isEthnicityRtl, setIsEthnicityRtl] = useState(false);

    const ethnicity = [
        { value: 'asn', label: 'Asian' },
        { value: 'hsl', label: 'Hispanic or Latino' },
        { value: 'aan', label: 'American Indian or Alaska Native' },
        { value: 'aam', label: 'Black or African American' },
        { value: 'wht', label: 'White' },
        { value: 'nhp', label: 'Native Hawaiian or Other Pacific Islander' }
    ] 

    //////////////////////////////////////////////////////////////
    // handleGenderSelect
    //////////////////////////////////////////////////////////////
    function handleGenderSelect(selectedOption){
        setSelectedGender(selectedOption.value)
    }

    //////////////////////////////////////////////////////////////
    // handleEthnicitySelect
    //////////////////////////////////////////////////////////////
    function handleEthnicitySelect(selectedOption){
        setSelectedEthnicity(selectedOption.value)
    }

    //////////////////////////////////////////////////////////////
    // slideHandler
    //////////////////////////////////////////////////////////////
    function slideHandler(direction){
        console.log(direction)
        slide('basicInformation', direction)
    }

    const getOrdinalNum = (number) => {
        let selector;
      
        if (number <= 0) {
          selector = 4;
        } else if ((number > 3 && number < 21) || number % 10 > 3) {
          selector = 0;
        } else {
          selector = number % 10;
        }
      
        return number + ['th', 'st', 'nd', 'rd', ''][selector];
      };

      const month = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

    //////////////////////////////////////////////////////////////
    // validateUserInformation
    //////////////////////////////////////////////////////////////
    function validateUserInformation(){

        fetch(baseAddress + "validateBasicInformation", {
            method: "POST",
            headers: {
            "content-type" : 'application/json'
            },
            body: JSON.stringify({
                firstName: firstName.current.value,
                lastName: lastName.current.value,
                phoneNumber: phoneNumber.current.value,
                emailAddress: emailAddress.current.value,
                password: password.current.value,
                confirmPassword: confirmPassword.current.value,
                dateOfBirth: birthDate,
                gender: selectedGender,
                ethnicity: selectedEthnicity,
                agreement: agreement.current.checked
            })
        })
        .then(res => {
            if (res.ok) return res.json()
            return res.json().then(json => Promise.reject(json))
        })
        .then(res => {

            let birthday = birthDate.getDate()
            let birthmonth = birthDate.getMonth()
            let birthyear = birthDate.getFullYear()


            setGeneralInfo({
                firstName: firstName.current.value,
                lastName: lastName.current.value,
                phoneNumber: phoneNumber.current.value,
                emailAddress: emailAddress.current.value,
                password: password.current.value,
                confirmPassword: confirmPassword.current.value,
                dateOfBirth: birthDate,
                gender: selectedGender,
                ethnicity: selectedEthnicity,
                birthday: month[birthmonth] + ' ' + getOrdinalNum(birthday) + ', ' + birthyear,
                agreement: agreement.current.checked
            })
            slideHandler(true)
        })
        .catch(error => {
            setErrorDetails(error)
            setErrorDisplay('block')
        })
    }

    //////////////////////////////////////////////////////////////
    // handleAgreement
    //////////////////////////////////////////////////////////////
    function handleAgreement(){
        console.log(agreement.current.checked)
        setSelectedAgreement(agreement.current.checked)
    }

    const agreement = useRef()
      
  return (
    <div style={{display: display ? 'block' : 'none'}}>
        <ErrorWindow display={errorDisplay} details={errorDetails} closeError={closeError}></ErrorWindow>
        <img className='common_icon_center' src={userPreview}></img>
        <div className='common_title_subtext'>Great!<br></br> Let's get your account set up!</div>
        <ProgressBar page='basicInformation'></ProgressBar>
        <div className='common_input_text'>First Name</div>
        <input ref={firstName} className='common_input'></input>
        <div className='common_input_text'>Last Name</div>
        <input ref={lastName} className='common_input'></input>
        <div className='common_input_text'>Date Of Birth</div>
        <DatePicker className='common_input' selected={birthDate} onChange={(date) => setBirthDate(date)} />
        <div className='common_input_text'>Gender</div>
        {/* Select gender type */}
        <Select
            onChange={handleGenderSelect}
            className="common_input_text"
            classNamePrefix="select"
            defaultValue={null}
            isDisabled={isGenderDisabled}
            isLoading={isGenderLoading}
            isClearable={isGenderClearable}
            isRtl={isGenderRtl}
            isSearchable={isGenderSearchable}
            name="color"
            options={gender}
            value={gender.filter(function(option) {
            return option.value === selectedGender;
            })}
        />
        <div className='common_input_text'>Ethnicity</div>
        {/* Select ethnicity type */}
        <Select
            onChange={handleEthnicitySelect}
            className="common_input_text"
            classNamePrefix="select"
            defaultValue={null}
            isDisabled={isEthnicityDisabled}
            isLoading={isEthnicityLoading}
            isClearable={isEthnicityClearable}
            isRtl={isEthnicityRtl}
            isSearchable={isEthnicitySearchable}
            name="color"
            options={ethnicity}
            value={ethnicity.filter(function(option) {
            return option.value === selectedEthnicity;
            })}
        />
        <div className='common_input_text'>Phone Number</div>
        <input ref={phoneNumber} className='common_input'></input>
        <div className='common_input_text'>Email Address</div>
        <input ref={emailAddress} className='common_input'></input>
        <div className='common_input_text'>Create Password</div>
        <input ref={password} type='password' className='common_input'></input>
        <div className='common_input_text'>Confirm Password</div>
        <input ref={confirmPassword} type='password' className='common_input'></input>
        
        <div  style={{marginTop: '40px'}}><input ref={agreement} type='checkbox' onClick={()=>handleAgreement()}></input> By checking this box, you are agreeing to have read and understand our terms of service outlined in our <a href='https://voterstack.com/policies' target='_blank' style={{textDecorationLine: 'underline'}}>policies</a> page.</div>
        
        
        <div className='common_button_combination' style={{marginTop: '3rem'}}>
            <div className='common_button common_cancel_button' onClick={()=>slideHandler(false)} >Cancel</div>
            <div></div>
            <div className='common_button common_submit_button' onClick={()=>validateUserInformation()}>Continue</div>
        </div>
    </div>
  )
}
