import React, {useEffect} from 'react'
import './policyFilter.css'

export default function PolicyFilter({display, filter, closeWindow}) {

  function selectionHandler(selection){
    closeWindow(selection)
  }



  return (
    <div style={{display: display}} className='policy_filter_overlay'>
      <div className='policy_filter_options_center'>
        <div className='policy_filter_title' style={{marginBottom: '3rem'}}>Filter Results</div>
        <div className={filter == "HCPV" ? 'policy_filter_highlight' : 'policy_filter_element'} onClick={()=>selectionHandler('HCPV')}>Highest Cumulative Priority Votes</div>
        <div className={filter == "LCPV" ? 'policy_filter_highlight' : 'policy_filter_element'} onClick={()=>selectionHandler('LCPV')}>Lowest Cumulative Priority Votes</div>
        <div className={filter == "HRPV" ? 'policy_filter_highlight' : 'policy_filter_element'} onClick={()=>selectionHandler('HRPV')}>Highest Registered Priority Votes</div>
        <div className={filter == "LRPV" ? 'policy_filter_highlight' : 'policy_filter_element'} onClick={()=>selectionHandler('LRPV')}>Lowest Registered Priority Votes</div>
        <div className={filter == "HUPV" ? 'policy_filter_highlight' : 'policy_filter_element'} onClick={()=>selectionHandler('HUPV')}>Highest Unregistered Priority Votes</div>
        <div className={filter == "LUPV" ? 'policy_filter_highlight' : 'policy_filter_element'} onClick={()=>selectionHandler('LUPV')}>Lowest Unregistered Priority Votes</div>


        <div className='policy_filter_element policy_filter_element_cancel' onClick={()=>selectionHandler('cancel')}>Cancel</div>
      </div>

    </div>
  )
}
