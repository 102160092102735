import React, {useState, useEffect} from 'react'
import ReviewIcon from '../../images/reviewInformation.png'
import './reviewInformation.css'
import ProgressBar from '../../progressBar/progressBar'

export default function ReviewInformation({display, information, slide, createAccount}) {

console.log(information)

useEffect(() => {
    window.scrollTo(0, 0)
}, [])

const [disableButton, setDisableButton] = useState(false)

//////////////////////////////////////////////////////////////
// slideHandler
//////////////////////////////////////////////////////////////
async function slideHandler(direction){
    if(direction){
        setDisableButton(true)
        let status = await createAccount()
        console.log(information)
        if(!status){
            return
        }
    }
    slide('review', direction)
}

  return (
    <div style={{display: display ? 'block' : 'none'}}>
            <img className='common_icon_center' src={ReviewIcon}></img>
            <div className='common_title_subtext'>Time to review!</div>
            <ProgressBar page='reviewInformation'></ProgressBar>
            <div className='common_note'>Please verify all of the information in which you entered is correct. If you need to make an edit, please hit the back button.</div>

            <div className='common_note' style={{marginTop: '.5rem'}}>Once you click checkout and have confirmed payment on the following screen, your information will be reviewed by a representative to determine identification and residential authenticity.</div>
            
            <div className='common_input_text'>Name:</div>
            <div className='review_information_user_entry'>{information.firstName + ' ' + information.lastName}</div>

            <div className='common_input_text'>Date Of Birth:</div>
            <div className='review_information_user_entry'>{information.birthday}</div>

            <div className='common_input_text'>Address:</div>
            <div className='review_information_user_entry'>{information.addressLineOne}</div>
            <div className='review_information_user_entry'>{information.addressLineTwo}</div>
            <div className='review_information_user_entry'>{information.city}</div>
            <div className='review_information_user_entry'>{information.state}</div>
            <div className='review_information_user_entry'>{information.zipCode}</div>

            <div className='common_input_text'>Email Address:</div>
            <div className='review_information_user_entry'>{information.emailAddress}</div>

            <div className='common_input_text'>Phone Number:</div>
            <div className='review_information_user_entry'>{information.phoneNumber}</div>

            <div className='common_input_text'>Gender:</div>
            <div className='review_information_user_entry'>{information.gender}</div>

            <div className='common_input_text'>Ethnicity:</div>
            <div className='review_information_user_entry'>{information.ethnicity}</div>

            <div className='review_information_image_combination' >
                <div className='review_information_proof_headers'>ID Proof</div>
                <div className='review_information_proof_headers'>Residence Proof</div>
            </div>


            <div className='review_information_image_combination' >
                <img className='review_information_proof_image' src={information.IDPhotoURL}/>
                <img className='review_information_proof_image' src={information.mailPhotoURL}/>
            </div>

            <div className='common_button_combination' >
                <div className='common_button common_cancel_button' isDisabled={disableButton} onClick={()=>slideHandler(false)} >Back</div>
                <div></div>
                <div className='common_button common_submit_button' isDisabled={disableButton} onClick={()=>slideHandler(true)}>Checkout</div>
            </div>
        </div>
  )
}
