import React, {useEffect, useState, useRef} from 'react'
import './myPolicy.css'
import topPriority from '../images/certifiedWinner.png'
import MetricType from '../metricType/metricType'
import CreatePolicy from '../createPolicy/createPolicy'
import UserStatement from '../userStatement/userStatement'
import ConfirmSubmission from '../confirmSubmission/confirmSubmission'
import SuccessSubmission from '../successSubmission/successSubmission'
import ErrorWindow from '../errorWindow/errorWindow'

import mixpanel from 'mixpanel-browser';


import TokenService from '../utilities/token.service'
import API from "../utilities/api"
import RedirectLogout from '../redirectLogout/redirectLogout'

import { dotWave } from 'ldrs'
dotWave.register()



export default function MyPolicy({type}) {

    const [districtName, setDistrictName] = useState(null)

    let myInformation = TokenService.getUser()


    mixpanel.init('1dba164ecdda445989be8b3d0b0271cc');

    // STATE RELATED ///////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////

    const [redirectLogout, setRedirectLogout] = useState(false)

    const [myPolicy, setMyPolicy] = useState({})
    
    const [createStatus, setCreateStatus] = useState('noStatement')

    const [createPolicyDisplay, setCreatePolicyDisplay] = useState('none')
    const [myPolicyDisplay, setMyPolicyDisplay] = useState('none')


    const [modifyLoad, setModifyLoad] = useState(false)

    // ERROR RELATED ///////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////
    const [errorDisplay, setErrorDisplay] = useState('none')
    const [errorDetails, setErrorDetails] = useState({title: '', description: ''})

    function closeError(){
        setErrorDisplay('none')
    }
    ////////////////////////////////////////////////////////////////////////////////////////////////


    useEffect(() => {
        setMyPolicy({})
        setCreatePolicyDisplay('none')
        setMyPolicyDisplay('none')
        setCreateStatus('noStatement')
        setErrorDisplay('none')
        setErrorDetails({title: '', description: ''})
        
        // Load my existing policy
        getMyPolicy()

        for(let i = 0; i < myInformation.districts.length; i++){
            if(myInformation.districts[i].districtType == type){
                setDistrictName(myInformation.districts[i].districtLabel)
            }
        }

    }, [type]);

    // Getting my self created policy
    function getMyPolicy(){
        
        API.post('getMyPolicy', {type: type})
        .then(res => {
            if(res.data.status === 'found'){
                setMyPolicyDisplay('none')
                setMyPolicy(res.data)
                setCreateStatus('loaded')

            } else {
                setMyPolicyDisplay('block')
                setMyPolicy({})
            }
        })
        .catch(err => {
            console.log(err)
            if(err === 'Forbidden'){ 
                setRedirectLogout(true)
                return false
            } else {
                setErrorDetails(err)
                setErrorDisplay('block')
                return false
            }
        })


    }

    // Expand policy creation
    function openPolicyCreation(source){
        setCreatePolicyDisplay('block')
    }

    // Collapse policy creation
    function closePolicyCreation(){
        setCreatePolicyDisplay('none') 
    }

    // Prompt "Are You Sure?"
    async function areYouSureHandler(myData){

        setMyPolicy(myData)
        setCreateStatus('finalizing')
    }

    let myMessage = 'Are you sure you want to submit this statement to those in your ' + type.toLowerCase() + ' district? You will only have the ability to vote once per governement district per A&S session. Please choose carefully.'

    let successMessage = 'Your ' + type.toLowerCase()  + ' policy has been submitted! Be sure to check in on the status of your statement frequently to view updated stats.' 

    // Upon confirmation, one will either advance or withdraw from finalization
    async function advanceOrWithdraw(direction){
        console.log(direction)

        if(direction === 'advance'){

            let status = await API.post('submitPolicyCreation', myPolicy)
            .then(res => {
                console.log(res.data)
                setMyPolicy(res.data)
                setCreateStatus('success')

                mixpanel.track("New Policy");

                return true
            })
            .catch(err => {
                if(err === 'Forbidden'){ 
                    setRedirectLogout(true)
                    return false
                } else {
                    setCreateStatus('started')
                    setErrorDetails(err)
                    setErrorDisplay('block')
                    return false

                }
            })

        } else {
            // Clear the contents !!!
            setMyPolicy({})
            setCreateStatus('started')
            
        }
    }

    function closeSuccess(){
        setMyPolicyDisplay('none')
        setCreateStatus('loaded')
    }

    // Expand policy creation
    async function modifyRenewal(){

        if(modifyLoad){
            return
        }

        setModifyLoad(true)

        let newRenewalState;

        if(myPolicy.renewal){
            newRenewalState = false
        } else {
            newRenewalState = true
        }

        let newMyPolicyState = myPolicy;
        newMyPolicyState.renewal = newRenewalState;

        let status = await API.post('modifyRenewal', {
            renewal: newRenewalState,
            type: type
        })
            .then(res => {
                getMyPolicy()
                setModifyLoad(false)
                return true
            })
            .catch(err => {
                if(err === 'Forbidden'){ 
                    setRedirectLogout(true)
                    
                    return false
                } else {
                    setCreateStatus('started')
                    setErrorDetails(err)
                    setErrorDisplay('block')
                    return false

                }
            })
        
    }

  return (
    <div className='my_policy_background'>
        <RedirectLogout trigger={redirectLogout}></RedirectLogout>
        <ErrorWindow display={errorDisplay} details={errorDetails} closeError={closeError}></ErrorWindow>
        {/* Error & Finalize*/}
        <ConfirmSubmission display={createStatus === 'finalizing' ? true : false} type={type} close={advanceOrWithdraw} message={myMessage}></ConfirmSubmission>
        <SuccessSubmission display={createStatus === 'success' ? true : false} type={type} message={successMessage} close={closeSuccess}></SuccessSubmission>
        <div style={{paddingTop: '.5rem'}}>
            {/* No policy? */}
            <div style={{display: myPolicyDisplay}} className='add_policy_outer_container'>

                <div style={{display: createPolicyDisplay === 'block' ? 'none' : 'block'}} className='add_policy_inner_container' onClick={()=>openPolicyCreation('noStatement')}>
    
                    <div style={{display: type == 'City' ? 'block' : 'none'}} className='add_policy_text_big'>Create a policy for the city of <br></br> <span style={{fontWeight: "bold"}}>{districtName}</span></div>
                    <div style={{display: type == 'Township' ? 'block' : 'none'}} className='add_policy_text_big'>Create a policy for <br></br> <span style={{fontWeight: "bold"}}>{districtName} township</span></div>
                    <div style={{display: type == 'County' ? 'block' : 'none'}} className='add_policy_text_big'>Create a policy for <br></br> <span style={{fontWeight: "bold"}}>{districtName}</span> county</div>
                    <div style={{display: type == 'State' ? 'block' : 'none'}} className='add_policy_text_big'>Create a policy for the state of <br></br> <span style={{fontWeight: "bold"}}>{districtName}</span>?</div>
                    <div style={{display: type == 'Federal' ? 'block' : 'none'}} className='add_policy_text_big'>Create a policy for the <br></br> <span style={{fontWeight: "bold"}}>{districtName}</span></div>
                </div>

                {/* Create a policy! */}
                <CreatePolicy display={createPolicyDisplay} type={type} policy={myPolicy} exitCreation={closePolicyCreation} submitCreation={areYouSureHandler}></CreatePolicy>
            </div>
            
            
            {/* Load my policy */}
            <div style={{display: createStatus === 'loaded' ? 'block' : 'none'}} className='fade-in-left add_policy_outer_container'>
                <div className='my_district_policy_title'>My {type} Policy</div>
                <l-dot-wave style={{float: 'right', marginTop: '-6px', marginRight: '70px', display: modifyLoad ? 'block' : 'none'}} size="45" speed=".65" color="white" ></l-dot-wave>
                <div style={{display: modifyLoad ? 'none' : 'block'}}>
                    <div style={{display: myPolicy.renewal === true ? 'none' : 'block'}} className='my_policy_edit_creation' onClick={()=>modifyRenewal()}><span style={{fontSize: '13pt', color: 'green'}}>&#8853;</span> Add Auto-Renewal</div>
                    <div style={{display: myPolicy.renewal === false ? 'none' : 'block'}} className='my_policy_edit_creation' onClick={()=>modifyRenewal()}><span style={{fontSize: '13pt', color: 'red'}}>&#8861;</span> Remove Auto-Renewal</div>
                </div>
                <UserStatement statementContent={myPolicy} session={false} type={false}></UserStatement>
                

                
            </div>
        </div>
    </div>
    
  )
}
