import React, {useRef, useEffect, useState} from 'react'
import './policySelect.css'
import { dotWave } from 'ldrs'
import {
    Routes,
    Route,
    useSearchParams,
    BrowserRouter,
    useNavigate
  } from "react-router-dom"

import TokenService from '../utilities/token.service'
import API from "../utilities/api"
import ErrorWindow from '../errorWindow/errorWindow'
import Header from '../header/header'
import Navigation from '../navigation/navigation'
import MetricType from '../metricType/metricType'
import UserStatement from '../userStatement/userStatement'
import MyPolicy from '../myPolicy/myPolicy'
import ConfirmSubmission from '../confirmSubmission/confirmSubmission'
import SuccessSubmission from '../successSubmission/successSubmission'
import PolicyFilter from '../policyFilter/policyFilter'

import mixpanel from 'mixpanel-browser';

import Select from 'react-select'

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    // dev code
    var websiteURL = "http://dev-customer-front.voterstack.com:3000/policySelect?"
} else {
    // production code
    var websiteURL = "https://voterstack.com/policySelect?"
}





export default function PolicySelect() {

    mixpanel.init('1dba164ecdda445989be8b3d0b0271cc');

    console.log(process.env.NODE_ENV)

    dotWave.register()

    const navigate = useNavigate()

    const [queryParameters] = useSearchParams()


    // ERROR RELATED ///////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////
    const [errorDisplay, setErrorDisplay] = useState('none')
    const [errorDetails, setErrorDetails] = useState({title: '', description: ''})

    function closeError(){
        setErrorDisplay('none')
    }
    //=============================================================================================

    // NAVIGATION RELATED ///////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////
    const [pageContentsDisplay, setPageContentsDisplay] = useState('block')
    const [navigationDisplay, setNavigationDisplay] = useState('none')

    function hidePage(){
        setPageContentsDisplay('none')
        setNavigationDisplay('block')
    }

    function displayPage(){
        setPageContentsDisplay('block')
        setNavigationDisplay('none')
    }
    //=============================================================================================

    // SCROLLING RELATED ////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////
    const [scrollPosition, setScrollPosition ] = useState(0)
    const scrollReference = useRef()

    function updateScroll(){

        if(scrollReference.current.scrollTop + 1 >= (scrollReference.current.scrollHeight - scrollReference.current.clientHeight)){
            getConstituentPolicies(queryParameters.get("type"), queryParameters.get("filter"), skipCount)
        }

        setScrollPosition({y: scrollReference.current.scrollTop, yMax: (scrollReference.current.scrollHeight - scrollReference.current.clientHeight)})
    }
    //=============================================================================================

    // THIS PAGE RELATED ////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////
    const [skipCount, setSkipCount] = useState(0)
    const [policyLoad, setPolicyLoad] = useState(false)
    const [memberPolicies, setMemberPolicies] = useState([])

    const [selectionStatus, setSelectionStatus] = useState('open')
    const [selectedPolicy, setSelectedPolicy] = useState({})
    const [selectedPolicyID, setSelectedPolicyID] = useState(null)

    const [filterDisplay, setFilterDisplay] = useState('none')


    const [priorityLoad, setPriorityLoad] = useState(false)

    const [myPolicy, setMyPolicy] = useState({statement: null, postedDate: null, registeredPriorityVotes: null, unregisteredPriorityVotes: null})
    //=============================================================================================


    // First Render
    useEffect(() => {

        resetAll()
        getConstituentPolicies(queryParameters.get("type"), queryParameters.get("filter"), 0)
        getMyPriority()

    }, [queryParameters.get("filter"), queryParameters.get("type")]);


    // ResetAll
    function resetAll(){
        setMemberPolicies([]);
        setSelectionStatus('open')
        setSelectedPolicyID(null)
        setSelectedPolicy({})
        displayPage()
        setFilterDisplay('none')
        window.scrollTo(0, 0)
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // Load Policies
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    async function getConstituentPolicies(type, filter, skip){

        if(policyLoad){
            return
        }

        setPolicyLoad(true)

        let policyRequest = {
            skipCount: skip,
            type: type,
            filter: filter
        }

        await API.post('getConstituentPolicies', policyRequest)
        .then(res => {

            setMemberPolicies(prevState => [...prevState, ...res.data.policies]);

            setSkipCount(res.data.skipCount)
            
            setTimeout(() => {
                setPolicyLoad(false)
            }, 1000);
        })
        .catch(err => { 
            setErrorDetails(err)
            setErrorDisplay('block')
        })
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // Get My Priority
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    async function getMyPriority(){

        await API.post('getMyPriority', {
            type: queryParameters.get("type")
        })
        .then(res => {

            if(res.data.status === 'done'){
                setSelectedPolicy(res.data)
                setSelectionStatus(res.data.status)
            } else {
                setSelectedPolicy({})
                setSelectionStatus('open')
            }
        })
        .catch(err => { 
            setErrorDetails(err)
            setErrorDisplay('block')
        })
    }
    

    function setSelectionPromptConfirm(id){
        setSelectionStatus('pending')
        setSelectedPolicyID(id)

    }



    async function advanceOrWithdraw(direction){

        console.log(direction)

        if(direction === 'advance'){

            console.log(priorityLoad)

            if(priorityLoad){
                return
            }
            setPriorityLoad(true)
     
            // Validate that you can vote
            await API.post('setAsPriority', {
                statementID: selectedPolicyID,
                type: queryParameters.get("type")
            })
            .then(res => {

                setPriorityLoad(false)

                mixpanel.track("Selected Priority");
                
                let newArray = []

                // Cycle through each iteration and remove the voting button
                for(let i = 0; i < memberPolicies.length; i++){

                    if(memberPolicies[i]._id === selectedPolicyID){
                        if(res.data.registeredVoter){
                            memberPolicies[i].registeredPriorityVotes = res.data.updatedVotecount
                        } else {
                            memberPolicies[i].unregisteredPriorityVotes = res.data.updatedVotecount
                        }
                        memberPolicies[i].available = false
                        setSelectedPolicy(memberPolicies[i])
                    } else {
                        memberPolicies[i].available = false
                        newArray.push(memberPolicies[i])
                    }
                }

                setMemberPolicies(newArray) 
                setSelectionStatus('success')
            })
            .catch(err => { 
                setErrorDetails(err)
                setErrorDisplay('block')
                return false
            }) 
        } else {
            // Clear the contents !!!
            setSelectionStatus('open')
            setSelectedPolicyID(null)
            setSelectedPolicy({})
        }
    }

    function closeSuccess(){
        setSelectionStatus('done')
    }


    

    function openFilterWindow(){
        setFilterDisplay('block')
    }

    function closeFilterWindow(localFilter){
        
        if(localFilter === 'cancel'){
            setFilterDisplay('none')
            return
        }

        // Update the webpage
        navigate('/policySelect?type=' + queryParameters.get("type") + '&filter=' + localFilter)
    }


    let submitVote = 'You will only be able to vote on a single selection during this A&S session. Please be sure before submission.'
    let successMessage = 'Thank you! Your selection has been successfully submitted!'

    return (
    <div className='screen_background' onScroll={updateScroll} ref={scrollReference}>
        <Header hideFunction={hidePage} openFunction={displayPage} navDisplay={navigationDisplay}></Header>
        <Navigation navigationDisplay={navigationDisplay} type={queryParameters.get("type")}></Navigation>
        <ErrorWindow display={errorDisplay} details={errorDetails} closeError={closeError}></ErrorWindow>
        <ConfirmSubmission display={selectionStatus == 'pending' ? true : false} type={queryParameters.get("type")} message={submitVote} close={advanceOrWithdraw}></ConfirmSubmission>
        <SuccessSubmission display={selectionStatus == 'success' ? true : false} type={queryParameters.get("type")} message={successMessage} close={closeSuccess}></SuccessSubmission>
        <PolicyFilter display={filterDisplay} filter={queryParameters.get("filter")} closeWindow={closeFilterWindow}></PolicyFilter>
        <div style={{display: pageContentsDisplay}} className='policy_select_screen_foreground' >
            
            {/* Filter */}
            <div className='policy_select_filter_container_bar'>
                <div></div>
                <div>
                    <i class="fa fa-filter policy_select_filter_button" onClick={()=>openFilterWindow()}></i>
                </div>
            </div> 
            

            {/* Container */}
            <div style={{boxSizing: 'borderBox', marginLeft: '8px', marginRight: '8px', maxWidth: '800px'}} >

                {/* My Policy */}
                <MyPolicy type={queryParameters.get("type")}></MyPolicy>

                {/* My Selection */}
                <div style={{display: selectionStatus == 'done' ? 'block' : 'none'}} className='policy_select_chosen_container fade-in-left'>
                    <div className='policy_select_chosen_title'>My Prioritized {queryParameters.get("type")} Policy</div>
                    <UserStatement statementContent={selectedPolicy} type={queryParameters.get("type")} session={null} handleSelection={null}></UserStatement>
                </div>


                {/*
                <div style={{fontSize: '20pt', color: 'white'}}>{scrollPosition.y}</div>
                <div style={{fontSize: '20pt', color: 'white'}}>{scrollPosition.yMax}</div> 
                */}

                {/* Constituent Policies */}
                <div>
                {
                    memberPolicies.map((member) => 
                        <div key={member._id}>
                            <div style={{marginTop: '1rem'}}></div>
                            <UserStatement statementContent={member} type={queryParameters.get("type")} session={'policySelect'} handleSelection={setSelectionPromptConfirm}></UserStatement>

                        </div>  
                )}
                </div>
                <l-dot-wave style={{margin: '0 auto', display: policyLoad ? 'block' : 'none'}} size="55" speed=".65" color="white" ></l-dot-wave>
                <div style={{marginTop: '2rem'}}></div>
        </div>



        </div>
    </div>
    )
}
