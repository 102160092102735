import React, {useEffect} from 'react'
import './howItWorks.css'
import {
    Routes,
    Route,
    useSearchParams,
    BrowserRouter,
    useNavigate
  } from "react-router-dom"

import proposal from '../images/newProposal.png'
import priority from '../images/newPriority.png'
import vote from '../images/newVote.png'
import goodBad from '../images/newArgue.png'
import politician from '../images/newPolitician.png'

import mixpanel from 'mixpanel-browser';

export default function HowItWorks() {
  const navigate = useNavigate()

  mixpanel.init('1dba164ecdda445989be8b3d0b0271cc');

  useEffect(() => {
    window.scrollTo(0, 0)

    mixpanel.track("Page Visit", {'Page': 'HowItWorks'});
}, [])

  /////////////////////////////////////////////////////////////////////
  // ReturnHome
  /////////////////////////////////////////////////////////////////////
  function returnHome(){
    navigate('/')
  }

  return (
    <div className="screen_background background_image">
            <div className="signup_screen_foreground">
                <div className="signup_outer_section">
                    <div className="signup_inner_section">
                        <div className='common_title_subtext how_it_works_title'>How It Works</div>

                        <img className='common_icon_center how_it_works_icon' src={proposal}></img>
                        <div className='common_title_subtext how_it_works_section_header'>1. Create A Policy</div>

                        <div className='common_input_text how_it_works_text'>Do you have a policy that you believe is important within your communal district?
                            This could be anything from wanting a tax cut, infrastructure changes, or putting a new law into effect which would better serve the people. 
                            Create an idea to share among the rest of your fellow constituents to see if they feel the same way!
                        </div>

                        <img className='common_icon_center how_it_works_icon' src={priority}></img>
                        <div className='common_title_subtext how_it_works_section_header'>2. Which Is The Most Important</div>

                        <div className='common_input_text how_it_works_text'>Once everyone has had a chance to submit their policy idea, we need to find out which one is the most important! Everyone in your district will have a chance to vote on your policy to determine if it's something the officials should focus on! You will have the chance to vote on a different policy as well!
                        </div>



                        <img className='common_icon_center how_it_works_icon' src={vote}></img>
                        <div className='common_title_subtext how_it_works_section_header'>3. Determine Your Stance</div>
                        <div className='common_input_text how_it_works_text'>
                            From there, we need to know how you feel about this policy - do you agree or disagree with it? Showing support for either side of the policy will help your respective officials determine which direction they should proceed.
                        </div>

                        <img className='common_icon_center how_it_works_icon' src={goodBad}></img>
                        <div className='common_title_subtext how_it_works_section_header'>4. Why Is It Good/Bad?</div>
                        <div className='common_input_text how_it_works_text'>
                            Next, it is always important to have all perspectives as to why an idea could be good or bad for the community. Constituents must substantiate the claims for their position for a policy. In addition, this will help officials understand all the variables to a more complex issue or idea.
                        </div>

                        <img className='common_icon_center how_it_works_icon' src={politician}></img>
                        <div className='common_title_subtext how_it_works_section_header'>5. Sent Off To Your Official!</div>
                        <div className='common_input_text how_it_works_text'>
                            Finally, this compiled policy with everyones input will be sent off to your politicians where they can make change happen! They now understand their constituents position on the communal topic as well as how to navigate the problem with the supplemental arguments included.
                        </div>

                        <div className='common_button common_submit_button' onClick={()=>returnHome()}>Return Home</div>
                    </div>
                </div>
            </div>
        </div>
  )
}
