import React, {useEffect, useState, useRef} from 'react'
import './officialResponse.css'
import TokenService from '../utilities/token.service'
import API from "../utilities/api"
import ErrorWindow from '../errorWindow/errorWindow'
import Header from '../header/header'
import Navigation from '../navigation/navigation'
import UserStatement from '../userStatement/userStatement'

import {useSearchParams, useNavigate} from "react-router-dom"


import topPriority from '../images/certifiedWinner.png'
import majorityPopulation from '../images/group.png'

import NoDoc from '../images/noDoc.png'

import OfficialThread from '../officialThread/officialThread'

import FirstTime from '../firstTime/firstTime'

import mixpanel from 'mixpanel-browser';

import NonResponsive from '../nonResponsive/nonResponsive'


export default function OfficialResponse() {
    let cookies = TokenService.getUser()
    const [cycleState, setCycleState] = useState(null)
    const [districts, setDistricts] = useState([{districtName: '', districtType: '', districtPhotoURL: ''}])


    const navigate = useNavigate()

    const [queryParameters] = useSearchParams()

    const [governmentActivity, setGovernmentActivity] = useState([])
    const [activityNoneDisplay, setActivityNoneDisplay] = useState(false)

    const [firstTime, setFirstTime] = useState(false)

    

    // ERROR RELATED ///////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////
    const [errorDisplay, setErrorDisplay] = useState('none')
    const [errorDetails, setErrorDetails] = useState({title: '', description: ''})

    function closeError(){
        setErrorDisplay('none')
    }
    //=============================================================================================

    // SCROLLING RELATED ////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////
    const [scrollPosition, setScrollPosition ] = useState(0)
    const scrollReference = useRef()

    function updateScroll(){

        if(scrollReference.current.scrollTop + 1 >= (scrollReference.current.scrollHeight - scrollReference.current.clientHeight)){
            console.log("get more")
            getGovernmentActivity(skipCount)
        }

        setScrollPosition({y: scrollReference.current.scrollTop, yMax: (scrollReference.current.scrollHeight - scrollReference.current.clientHeight)})
    }
    //=============================================================================================

    mixpanel.init('1dba164ecdda445989be8b3d0b0271cc');

    // First Render
    useEffect(() => {
        
        const onPageLoad = () => {
            console.log('page loaded');
            // do something else

            window.scrollTo({ top: -30, behavior: "smooth" })

            getGovernmentActivity(0)
            setCycleState(cookies.cycle)
            setDistricts(cookies.districts)

            mixpanel.track("Page Visit", {'Page': 'Official Response'});

            setFirstTime(queryParameters.get("firstTime"))

          };
      
          // Check if the page has already loaded
          if (document.readyState === 'complete') {
            onPageLoad();
          } else {
            window.addEventListener('load', onPageLoad, false);
            // Remove the event listener when component unmounts
            return () => window.removeEventListener('load', onPageLoad);
          }
        
        
    }, []);

    /////////////////////////////////////////////////////////////////////////////////////////////////
    const [pageContentsDisplay, setPageContentsDisplay] = useState('block')
    const [navigationDisplay, setNavigationDisplay] = useState('none')

    function hidePage(){
        setPageContentsDisplay('none')
        setNavigationDisplay('block')
    }

    function displayPage(){
        setPageContentsDisplay('block')
        setNavigationDisplay('none')
    }
    /////////////////////////////////////////////////////////////////////////////////////////////////


    const [skipCount, setSkipCount] = useState(0)
    const [reportLoad, setReportLoad] = useState(false)


    async function getGovernmentActivity(skip){

        if(reportLoad){
            return
        }

        setReportLoad(true)

         // Validate that you can vote
         await API.post('getGovernmentReports', {
            skip: skip
        })
        .then(res => {
            console.log(res.data)

            if(res.data.reportList.length === 0 && res.data.skipCount === 0){
                setActivityNoneDisplay(true)
            } else {
                setGovernmentActivity(prevState => [...prevState, ...res.data.reportList]);
                setSkipCount(res.data.skipCount)
            }

            setTimeout(() => {
                setReportLoad(false)
            }, 1000);

        })
        .catch(err => { 
            setErrorDetails(err)
            setErrorDisplay('block')
            return false
        }) 
    }

    const [officialThread, setOfficialThread] = useState({display: "none", threadInfo: {reportID: null, officialID: null}})

    function openOfficialThread(reportID, officialID){
        console.log(reportID)
        console.log(officialID)
        setOfficialThread({display: "block", threadInfo: {reportID: reportID, officialID: officialID}})
    }

    function closeOfficialThread(){
        setOfficialThread({display: "none", threadInfo: {reportID: null, officialID: null}})
    }

    function closeFirstTime(){
        setFirstTime(false)
        navigate('/officialResponses')
    }

    function selectDistrict(district){

        mixpanel.track("Page Visit", {'Page': district.districtType + " " + cycleState});
        
        if(cycleState === 'policySelect'){
          navigate('/policySelect?type=' + district.districtType + '&filter=HCPV')
        } else {
          navigate('/policyDebate?type=' + district.districtType + '&filter=HCV')
        }
    
    }

    // Non Response RELATED ///////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////
    const [nonResponseDisplay, setNonResponseDisplay] = useState('none')
    const [officialData, setOfficialData] = useState({})

    function closeNonResponse(){
        setOfficialData({})
        setNonResponseDisplay('none')
    }

    function openNonResponse(official){

        official.firstSet = official.officePhoneNumber.slice(0,3)
        official.midSet = official.officePhoneNumber.slice(3,6)
        official.lastSet = official.officePhoneNumber.slice(6,10)

        setOfficialData(official)
        setNonResponseDisplay(true)
    }

  return (
    <div className='screen_background' onScroll={updateScroll} ref={scrollReference} >
        <div ></div>
        <Header hideFunction={hidePage} openFunction={displayPage} navDisplay={navigationDisplay}></Header>
        <Navigation navigationDisplay={navigationDisplay} ></Navigation>
        <OfficialThread threadInfo={officialThread} closeThread={closeOfficialThread}></OfficialThread>
        <FirstTime firstTimeState={firstTime} closeFirstTime={closeFirstTime}></FirstTime>
        <NonResponsive display={nonResponseDisplay} data={officialData} closeWindow={closeNonResponse}></NonResponsive>

        <div style={{display: pageContentsDisplay}} className='official_response_screen_foreground'>

            <div style={{display: activityNoneDisplay ? 'block' : 'none'}} className='official_response_section'>
                <img className='official_response_no_doc' src={NoDoc}></img>
                <div className='official_response_text'>There are currently no government responses at this time. Please check back on the next Policy Selection session for an update. <br></br><br></br>
                    <span>{cycleState === 'policySelect' ? 'In the meantime, please click on one of the following district scopes to participate in the Policy Selection session:' : null}</span>    
                    <span>{cycleState === 'policyDebate' ? 'In the meantime, please click on one of the following district scopes to participate in the Policy Debate session:' : null}</span>
                </div>     
            
                <div className="official_response_no_response_flex_container">
                {
              districts.map((district) =>
                <div className='official_response_no_response_flex_element' onClick={()=>selectDistrict(district)}>
                    {district.districtType}
                </div>
            )}
                </div>
            </div>


        {
            governmentActivity.map((activityObject) => 
            <div>
                <div className='official_response_section'>

                    <div className='official_response_name_title'>{activityObject.districtName} {activityObject.topVoteContent.type} District</div>
                    <div className='official_response_type_title' style={{fontSize: '13pt'}}>Session #{activityObject.sessionNumber} Results</div>
                    <div className='official_response_type_title'>Compiled on {activityObject.readableDate}</div>
        
                    <hr style={{marginBottom: '1.15rem', marginTop: '.75rem'}}></hr>
                    
                    
                    <UserStatement statementContent={activityObject.topVoteContent} type={activityObject.topVoteContent.type} session={'full'}></UserStatement>

                    <div className='official_response_full_report_button' onClick={()=> window.open(activityObject.reportURL, "_blank")}>View Full Report</div>
                    {/* <div>Click here to view full report</div> */}

                    {/* Responders */}
                    <div className='official_response_responsive_title'>Responded ({activityObject.responseCount})</div>
                    <div className='official_response_image_array'>
                        {
                            activityObject.officials.map((official) => {
                                return official.responded == true ? (
                                    <img className='official_response_profile_image' src={official.profilePictureURL} onClick={()=>openOfficialThread(activityObject._id, official._id)}/>
                                ) : (<div style={{height: '1rem'}}></div>)
                                
                            
                            })
                        }
                    </div>
                    
                    {/* Non Responders */}
                    <div className='official_response_responsive_title'>Not Responded ({activityObject.nonResponseCount})</div>
                    <div className='official_response_image_array'>
                        {
                            activityObject.officials.map((official) => {
                                return official.responded == false ? (
                                    <img className='official_response_profile_image' src={official.profilePictureURL} onClick={()=>openNonResponse(official)}/>
                                ) : (<div style={{height: '1rem'}}></div>)
                                
                            
                            })
                        }
                    </div>

                    
                </div>
            </div>
            
            
        )}




        </div>
    </div>
  )
}
